import { Flex, Heading, Stack, Text, Box } from '@chakra-ui/react';
import { TopNav } from '../../components/top-nav/TopNav';
import { useTranslation } from 'react-i18next';

const TermsAndConditions = () => {
  const { t } = useTranslation();

  return (
    <>
      <TopNav />
      <Flex flexDirection="column" flex={1} pb="150px" mt="72px">
        <Heading
          as="h1"
          fontSize="7xl"
          fontWeight="medium"
          color="white"
          mb="8"
          px="24"
          py="16"
          backgroundColor="baseBlue.700"
        >
          {t('termsAndConditions.title')}
        </Heading>

        <Stack px="24" gap="4">
          <Text>{t('termsAndConditions.paragraph#1.body')}</Text>
          <Box>
            <Heading as="h4" fontSize="2xl">
              {t('termsAndConditions.paragraph#2.title')}
            </Heading>
            <Text>{t('termsAndConditions.paragraph#2.body')}</Text>
          </Box>
          <Box>
            <Heading as="h4" fontSize="2xl">
              {t('termsAndConditions.paragraph#3.title')}
            </Heading>
            <Text>{t('termsAndConditions.paragraph#3.body')}</Text>
          </Box>
          <Box>
            <Heading as="h4" fontSize="2xl">
              {t('termsAndConditions.paragraph#4.title')}
            </Heading>
            <Text>{t('termsAndConditions.paragraph#4.body')}</Text>
          </Box>
          <Box>
            <Heading as="h4" fontSize="2xl">
              {t('termsAndConditions.paragraph#5.title')}
            </Heading>
            <Text>{t('termsAndConditions.paragraph#5.body')}</Text>
          </Box>
        </Stack>
      </Flex>
    </>
  );
};

export default TermsAndConditions;
