import React, { useMemo, createContext, ReactNode, useState } from 'react';
import { Message } from '../genius-chat/GeniusChatModal';
import { sendWizardMessage, TextPortion } from '../../services/wizard-chat/wizard-chat';

export interface PlanGeniusContextType {
  isChatOpen: boolean;
  setIsChatOpen: React.Dispatch<React.SetStateAction<boolean>>;
  conversation: Message[];
  setConversation: React.Dispatch<React.SetStateAction<Message[]>>;
  chatInputValue: string;
  setChatInputValue: React.Dispatch<React.SetStateAction<string>>;
  onChatMessageSubmit: (text: string) => Promise<void>;
  context: Record<string, string>;
  setContext: React.Dispatch<React.SetStateAction<Record<string, string>>>;
  workflowId: string;
  setWorkflowId: React.Dispatch<React.SetStateAction<string>>;
}

interface PlanGeniusProviderProps {
  children: ReactNode;
}

export const PlanGeniusContext = createContext<PlanGeniusContextType>({
  isChatOpen: false,
  setIsChatOpen: () => {},
  conversation: [],
  setConversation: () => {},
  chatInputValue: '',
  setChatInputValue: () => {},
  onChatMessageSubmit: () => Promise.resolve(),
  context: {},
  setContext: () => {},
  workflowId: '',
  setWorkflowId: () => {},
});

export const PlanGeniusContextProvider = ({ children }: PlanGeniusProviderProps) => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [conversation, setConversation] = useState<Message[]>([]);
  const [chatInputValue, setChatInputValue] = useState<string>('');
  const [context, setContext] = useState({});
  const [workflowId, setWorkflowId] = useState<string>('');
  const onChatMessageSubmit = async (text: string) => {
    if (!text) return;
    const message: Message = { text, role: 'user', portions: [{ type: 'text', text, thinking: true }] };
    const newConversation = [...conversation, message];

    setConversation(newConversation);

    setChatInputValue('');

    let response;

    try {
      response = await sendWizardMessage({ context, conversation: newConversation, setConversation });
    } catch (e) {
      (message.portions[0] as TextPortion).error = true;
    } finally {
      (message.portions[0] as TextPortion).thinking = false;
    }

    setConversation([...newConversation, ...(response ? [response] : [])]);
  };
  const value = useMemo(
    () => ({
      conversation,
      setConversation,
      chatInputValue,
      setChatInputValue,
      onChatMessageSubmit,
      isChatOpen,
      setIsChatOpen,
      context,
      setContext,
      workflowId,
      setWorkflowId,
    }),
    [conversation, chatInputValue, isChatOpen, workflowId],
  );

  return <PlanGeniusContext.Provider value={value}>{children}</PlanGeniusContext.Provider>;
};
