import './plan-editor.css';
import React, { useEffect } from 'react';
import '@mdxeditor/editor/style.css';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor/MDXEditor';
import { headingsPlugin } from '@mdxeditor/editor/plugins/headings';
import { listsPlugin } from '@mdxeditor/editor/plugins/lists';
import { linkPlugin } from '@mdxeditor/editor/plugins/link';
import { quotePlugin } from '@mdxeditor/editor/plugins/quote';
import { diffSourcePlugin } from '@mdxeditor/editor/plugins/diff-source';
import { markdownShortcutPlugin } from '@mdxeditor/editor/plugins/markdown-shortcut';
import { thematicBreakPlugin } from '@mdxeditor/editor/plugins/thematic-break';
import { UndoRedo } from '@mdxeditor/editor/plugins/toolbar/components/UndoRedo';
import { BoldItalicUnderlineToggles } from '@mdxeditor/editor/plugins/toolbar/components/BoldItalicUnderlineToggles';
import { DiffSourceToggleWrapper } from '@mdxeditor/editor/plugins/toolbar/components/DiffSourceToggleWrapper';
import { BlockTypeSelect } from '@mdxeditor/editor/plugins/toolbar/components/BlockTypeSelect';
import { ListsToggle } from '@mdxeditor/editor/plugins/toolbar/components/ListsToggle';
import { toolbarPlugin } from '@mdxeditor/editor/plugins/toolbar';
import { TopNav } from '../../components/top-nav/TopNav';
import { Center, Flex, Button, Box, Text, Heading, Progress } from '@chakra-ui/react';
import { PlanGeniusContext } from '../../components/plan-genius-context/PlanGeniusContext';
import { exportPlanToPDF, getWorkflowStatus } from '../../services/plan-generation';
import { businessPlanTemplate, headerTemplate } from './templates/templates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import eta from 'eta';
import { ToastErrorContainer } from '../../components/toast-errors/ToastErrorContainer';
import { ErrorWithMessage } from '../../types/error';
import { Footer } from '../../Footer';
import { InsertImage, imagePlugin } from '@mdxeditor/editor';
import { useTranslation } from 'react-i18next';

async function renderTemplate(template: string, info: Record<string, string>) {
  return eta.render(template, info);
}

export default function PlanEditor() {
  const { t } = useTranslation();
  const { workflowId, context } = React.useContext(PlanGeniusContext);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isExporting, setIsExporting] = React.useState(false);
  const [progress, setProgress] = React.useState(3);
  const [errorMessage, setErrorMessage] = React.useState<ErrorWithMessage | null>(null);
  const editorRef = React.useRef<MDXEditorMethods>(null);
  const handleCloseErrorToast = () => setErrorMessage(null);
  
  const handleExportToPDF = async () => {
    const header = await renderTemplate(headerTemplate, { businessName: context.businessName });
    const businessPlan = editorRef.current?.getMarkdown();

    if (businessPlan) {
      exportPlanToPDF(header + businessPlan, setIsExporting, setErrorMessage);
    }
  };

  useEffect(() => {
    const endTime = Date.now() + 60000; // 1 minute from now
    const intervalId = setInterval(() => {
      // Generate a random progress step between 1 and 3
      const randomProgressStep = Math.floor(Math.random() * 3) + 1;
      
      // Update the progress state with the random step, but ensure it doesn't exceed 100
      if (isLoading) {
        setProgress((prev) => Math.min(prev + randomProgressStep, 100));
      }
      
      if (Date.now() > endTime) {
        // Clear the interval when time is up
        clearInterval(intervalId);
      }
    }, 1000); // Adjust the interval as needed
  
    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (workflowId) {
        setIsLoading(true);
        const { execution } = await getWorkflowStatus(workflowId);

        if (execution[0].state !== 'ACTIVE') {
          if (execution[0].state === 'SUCCEEDED') {
            const plan = JSON.parse(execution[0].result);
            const renderedPlan = await renderTemplate(businessPlanTemplate(), { ...plan });

            editorRef.current?.setMarkdown(renderedPlan);
          }
          clearInterval(intervalId);
          setIsLoading(false);
        }
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
      localStorage.removeItem('pg-form-state');
      localStorage.removeItem('pg-active-step');
    }
  }, [workflowId]);

  return (
    <Flex flexDirection="column" minHeight="100%">
      <TopNav />
      <Flex flexDirection="column" flex={1} mt="72px">
        <ToastErrorContainer errorMessage={errorMessage} onClose={handleCloseErrorToast} />
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          pt={['8', '8', '16', '16', '16']}
          pb={['250px', '250px', '420px']}
          backgroundImage="url('/hero.jpg')"
          backgroundSize="cover"
          backgroundPosition="left"
        />
        <Center mt="-450px" px={['8', '8', '16', '16', '16']}>
          <Flex
            backgroundColor="white"
            boxShadow="2xl"
            borderRadius="lg"
            width={['100%', '100%', '810px', '810px', '60%']}
            minHeight={['auto', 'auto', 'auto', '575px', '575px']}
            paddingBottom="1rem"
            flexDirection="column"
          >
            <Flex px={6} py={3} borderTopRadius={7} backgroundColor="baseBlue.700" justifyContent="space-between">
              <Heading
                as="h3"
                size="lg"
                color="white"
                borderTopRadius={7}
              >
                {t('editor.planPreview')}
              </Heading>
              <Button
                leftIcon={<FontAwesomeIcon icon={faFilePdf} size="sm" />}
                colorScheme="green"
                onClick={handleExportToPDF}
                isLoading={isExporting}
                isDisabled={isLoading}
              >
                {t('editor.exportPlanToPDF')}
              </Button>
            </Flex>
            <Box px={6} py={4} fontSize="medium">
              <Text>{t('editor.text')}</Text>
            </Box>
            <Flex flex={1} position="relative">
              {isLoading && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  backgroundColor="rgba(255,255,255,0.5)"
                  zIndex="1000"
                >
                  <Center width="100%" height="100%">
                    <Flex gap={4} flexDirection="column" textAlign="center" minWidth="40%">
                      <Text>{t('editor.loading')}</Text>
                      <Progress hasStripe value={progress} isAnimated />
                    </Flex>
                  </Center>
                </Box>
              )}
              <MDXEditor
                ref={editorRef}
                markdown={''}
                plugins={[
                  headingsPlugin(),
                  listsPlugin(),
                  linkPlugin(),
                  quotePlugin(),
                  imagePlugin(),
                  thematicBreakPlugin(),
                  markdownShortcutPlugin(),
                  toolbarPlugin({
                    toolbarContents: () => (
                      <>
                        <DiffSourceToggleWrapper>
                          <UndoRedo />
                          <BoldItalicUnderlineToggles />
                          <BlockTypeSelect />
                          <ListsToggle />
                          <InsertImage />
                        </DiffSourceToggleWrapper>
                      </>
                    ),
                  }),
                  diffSourcePlugin(),
                ]}
                contentEditableClassName="plan-editor"
              />
            </Flex>
          </Flex>
        </Center>
        <Center marginTop="1rem">
          <Button
            leftIcon={<FontAwesomeIcon icon={faFilePdf} size="sm" />}
            colorScheme="green"
            onClick={handleExportToPDF}
            isLoading={isExporting}
            isDisabled={isLoading}
          >
            {t('editor.exportPlanToPDF')}
          </Button>
        </Center>
      </Flex>
      <Flex mt="auto" justifyContent="flex-start" px="2" py="2" bgColor="transparent">
        <Footer />
      </Flex>
    </Flex>
  );
}
