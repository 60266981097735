import jwt_decode from 'jwt-decode';

export function login(jwtToken) {
  try {
    const decoded = jwt_decode(jwtToken);
    setWithExpiry('token', jwtToken, decoded.exp * 1000);

    return getCurrentUser();
  } catch (error) {
    console.error('Error:', error);
    return undefined;
  }
}

export function logout() {
  localStorage.removeItem('token');
}

export function getToken() {
  return getWithExpiry('token');
}

export function getCurrentUser() {
  const token = getToken();
  if (!token) {
    return undefined;
  }

  const decoded = jwt_decode(token);

  return {
    email: decoded.email,
    givenName: decoded.given_name,
    familyName: decoded.family_name,
    name: decoded.name,
    picture: decoded.picture,
  };
}

export function setScopedToken(provider, token) {
  return set(`scopedToken-${provider}`, token);
}

export function getScopedToken(provider) {
  return get(`scopedToken-${provider}`);
}

function setWithExpiry(key, value, expiry) {
  const item = {
    value,
    expiry,
  };

  set(key, item);
}

function set(key, item) {
  localStorage.setItem(key, JSON.stringify(item));
}

function get(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  try {
    return JSON.parse(itemStr);
  } catch {
    return null;
  }
}

function getWithExpiry(key) {
  const item = get(key);
  if (!item) {
    return null;
  }

  try {
    const now = new Date();

    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } catch {
    return null;
  }
}

export function signupWithFirebase({ username, password, action }, setAccountInfo, setErrorMessage) {
  return fetch(
    process.env.REACT_APP_API_BASE_URL + '/signup',
    // 'http://localhost:8080',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, action }),
    },
  )
    .then(async (res) => {
      if (!res.ok) {
        const error = await res.json();
        throw new Error(`${error.error}`);
      }
      const { user } = await res.json();

      if (user) {
        setAccountInfo(user);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
      const message =
        error.message.includes('invalid-credential') || error.message.includes('invalid-email')
          ? 'Invalid username or password. Please try again.'
          : 'Failed to create user account. Please try again later. Please contact us if the problem persists.';
      setErrorMessage({
        error,
        message,
      });
    });
}
