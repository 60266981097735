import React from 'react';
import { Toast, Portal } from '@chakra-ui/react';

interface ErrorMessage {
  message: string;
}

type ToastErrorContainerProps = {
  errorMessage: ErrorMessage | null;
  onClose: () => void;
};

export function ToastErrorContainer(props: ToastErrorContainerProps) {
  const { errorMessage, onClose } = props;

  return (
    errorMessage && (
      <Toast
        onClose={onClose}
        duration={7000}
        isClosable
        status="error"
        title="Error"
        description={errorMessage.message}
        position="top"
        containerStyle={{ borderRadius: '0px' }}
      />
    )
  );
}
