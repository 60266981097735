import { NavLink } from 'react-router-dom';

import { logout } from '../../services/auth/AuthService';
import { useBoundStore } from '../../store';
import { Flex, Image, Button, Text } from '@chakra-ui/react';
import LanguageSelector from '../language-selector/LanguageSelector';
import { useTranslation } from 'react-i18next';

export function TopNav() {
  const { t } = useTranslation();
  const { user, onLogout } = useBoundStore();

  const handleLogout = () => {
    logout();
    onLogout();
    localStorage.removeItem('pg-form-state');
    localStorage.removeItem('pg-active-step');
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      py="4"
      px="6"
      className="navbar"
      position="fixed"
      top="0"
      bgColor="white"
      width="100%"
      gap="8"
      zIndex={1000}
      boxShadow="sm"
    >
      <Flex alignItems="center" gap="8">
        <NavLink to="/">
          <Image src="/logo.png" maxHeight="40px" alt="Plan Genius" />
        </NavLink>
      </Flex>
      <Flex alignItems="center" gap="8">
        <Text fontFamily="heading" fontSize="2xl" display={['none', 'none', 'none', 'none', 'block']}>
          {t('tagline')}
        </Text>
        <LanguageSelector />
        {user && (
          <Button variant="outline" colorScheme="blackAlpha" onClick={handleLogout}>
            {t('logout')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
