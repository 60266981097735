import React, { lazy, useEffect, Suspense } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';

import TermsAndConditions from './screens/terms-and-conditions/TermsAndConditions';
import { AuthLayout } from './components/auth-layout/AuthLayout';
import PrivacyPolicy from './screens/privacy-policy/PrivacyPolicy';
import { initGA } from './utils/ga-utils';
import { Flex } from '@chakra-ui/react';
import { PlanGeniusContextProvider } from './components/plan-genius-context/PlanGeniusContext';
import PlanEditor from './screens/plan-editor/PlanEditor';
import { Landing } from './screens/landing/Landing';

const PlanGeniusWizard = lazy(() => import('./screens/plan-genius-wizard/PlanGeniusWizard'));

function AppRouter() {
  useEffect(() => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <PlanGeniusContextProvider>
        <BrowserRouter>
          <Flex direction="column" minHeight="100%" height="100%">
            <Routes>
              <Route index path="/" element={<Landing />}></Route>
              <Route path="/linkedin" element={<LinkedInCallback />} />

              <Route element={<Outlet />}>
                <Route path="/termsandconditions" element={<TermsAndConditions />}></Route>
                <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
                <Route path="/wizard" element={<PlanGeniusWizard />}></Route>
                <Route element={<AuthLayout />}>
                    <Route path="/editor" element={<PlanEditor />}></Route>
                </Route>
              </Route>
              <Route index path="*" element={<Landing />}></Route>
            </Routes>
          </Flex>
        </BrowserRouter>
      </PlanGeniusContextProvider>
    </Suspense>
  );
}

export default AppRouter;
