import { Navigate, Outlet } from 'react-router-dom';

import { useBoundStore } from '../../store';
import { Box } from '@chakra-ui/react';

export const AuthLayout = () => {
  const { user } = useBoundStore();

  return user ? (
    <Box height="100%">
      <Outlet />
    </Box>
  ) : (
    <Navigate to="/" />
  );
};
