import { Menu, MenuButton, MenuList, MenuItem, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const lngs: { [key: string]: { nativeName: string } } = {
  en: { nativeName: 'English' },
  es: { nativeName: 'Español' },
};

export default function LanguageSelector() {
  const { i18n } = useTranslation();

  return (
    <Menu>
      <MenuButton as={Button} aria-label="Options" variant="outline">
        <Text textTransform="uppercase">{i18n.resolvedLanguage || 'en'}</Text>
      </MenuButton>
      <MenuList>
        {Object.keys(lngs).map((lng) => (
          <MenuItem key={lng} onClick={() => i18n.changeLanguage(lng)} disabled={i18n.resolvedLanguage === lng}>
            {lngs[lng].nativeName}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
