import i18n from "../../../i18n";

// export const businessPlanTemplate: () => string = () => `
// # ${i18n.t('planTemplate.executiveSummary')}
// <%= it.executiveSummary %>

// # ${i18n.t('planTemplate.companyDescription')}
// <%= it.companyDescription %>

// # ${i18n.t('planTemplate.SWOTStrategicAnalysis')}
// <%= it.swotStrategicAnalysis %>

// # ${i18n.t('planTemplate.financialForecast')}
// <%= it.financialForecast %>

// # ${i18n.t('planTemplate.customerAnalysis')}
// <%= it.customerAnalysis %>

// # ${i18n.t('planTemplate.industryAndCompetitiveAnalysis')}
// <%= it.industryAndCompetitiveAnalysis %>

// # ${i18n.t('planTemplate.TAMCalculation')}
// <%= it.tamCalculation %>`;

export const businessPlanTemplate: () => string = () => `
<%= it.executiveSummary %>

<%= it.companyDescription %>

<%= it.swotStrategicAnalysis %>

<%= it.financialForecast %>

<%= it.customerAnalysis %>

<%= it.industryAndCompetitiveAnalysis %>

<%= it.tamCalculation %>`;

export const headerTemplate: string = `
<h1 style="text-align: center;">Business Plan for <%= it.businessName %></h1>
<p style="text-align: center;">
This business plan was generated by PlanGenius by FourthNexus, an automated business plan generator leveraging the power of GPT technology. The information contained in this plan is generated based on the inputs provided by the user, and is intended for informational purposes only. FourthNexus makes no guarantees or warranties as to the accuracy or completeness of the information presented in this plan, and users are encouraged to review and verify all information before relying on it for any purpose. For more information about PlanGenius and our services, please visit our website at <a href="https://plangenius.ai">plangenius.ai</a> or contact us at <a href="mailto:support@fourthnexus.com">support@fourthnexus.com</a>
</p>
<div class="page-break"></div>\n\n`;
