import { useTranslation } from 'react-i18next';

export function Footer() {
  const { t } = useTranslation();
  const today = new Date();
  return (
    <small>
      &copy; Copyright {today.getFullYear()} FourthNexus. {t('footer.rightsReserved')}.
    </small>
  );
}
