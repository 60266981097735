import { getToken } from './auth/AuthService';
import { ErrorWithMessage } from '../types/error';

declare global {
  var grecaptcha: {
    enterprise: {
      ready: (callback: () => void) => void;
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
    };
  };
}

export const getWorkflowStatus = async (workflowId: string) => {
  return fetch(
    process.env.REACT_APP_API_BASE_URL + '/workflowStatus?id=' + workflowId,
    // 'http://localhost:8081/workflowStatus?id=' + workflowId,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
    },
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
      }
      return res.json();
    })
    .catch((error) => {
      console.error('Error:', error.message);
    });
}

export const generatePlan = async (
  values: any,
  setIsSubmitted: (isSubmitted: boolean) => void,
  setErrorMessage: (errorMessage: ErrorWithMessage) => void,
  setWorkflowId: (id: string) => void,
) => {
  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY!;
  
  return new Promise((resolve) => grecaptcha.enterprise.ready(async () => {
    const token = await grecaptcha.enterprise.execute(recaptchaKey, { action: 'submit' });
    
    if (token) {
      return fetch(
        process.env.REACT_APP_API_BASE_URL + '/generatePlan',
        // 'http://localhost:8080',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + getToken(),
          },
          body: JSON.stringify({ token, values }),
        },
      )
        .then(async (res) => {
          if (!res.ok) {
            throw new Error(`Failed to fetch: ${res.status} ${res.statusText}`);
          }
          const { success, id} = await res.json();
          
          if (success) {
            setIsSubmitted(true);
            setWorkflowId(id);
            resolve(id);
          }
        })
        .catch((error) => {
          console.error('Error:', error.message);
          setErrorMessage({
            error: error.message,
            message: 'Failed to submit the form. Please try again later. Please contact us if the problem persists.',
          });
          resolve(null);
        });
    }
    return resolve(null);
  }));
};

export const exportPlanToPDF = async (
  plan: string,
  setIsLoading: (isLoading: boolean) => void,
  setErrorMessage: (errorMessage: ErrorWithMessage) => void,
) => {
  setIsLoading(true);
  return fetch(
    process.env.REACT_APP_API_BASE_URL + '/exportPlanToPDF',
    // 'http://localhost:8080',
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getToken(),
      },
      body: JSON.stringify({
        markdown: plan,
      }),
    },
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(`Failed to export: ${res.status} ${res.statusText}`);
      }
      return res.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'BusinessPlan.pdf';
      link.click();

      setTimeout(() => window.URL.revokeObjectURL(url), 100);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error.message);
      setIsLoading(false);
      setErrorMessage({
        error: error.message,
        message: 'Failed to export the plan. Please try again later. Please contact us if the problem persists.',
      });
    });
};
