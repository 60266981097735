import React from 'react';
import ReactDOM from 'react-dom/client';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';

import i18n from './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const theme = extendTheme({
  colors: {
    baseBlue: {
      '50': '#E9F0FB',
      '100': '#C2D6F4',
      '200': '#9BBCED',
      '300': '#74A1E7',
      '400': '#4D87E0',
      '500': '#266CD9',
      '600': '#1F57AD',
      '700': '#174182',
      '800': '#0F2B57',
      '900': '#08162B',
    },
  },
});

i18n.on('initialized', () => {
  root.render(
    <React.Suspense fallback="loading">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
        <React.StrictMode>
          <ChakraProvider theme={theme} resetCSS>
            <App />
          </ChakraProvider>
        </React.StrictMode>
      </GoogleOAuthProvider>
    </React.Suspense>,
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
