import { StateCreator } from 'zustand';

import { getCurrentUser } from '../services/auth/AuthService';

export type CredentialsSlice = {
  user: any; // FIXME: Add a type for User
  onLogin: (user: any) => void;
  onLogout: () => void;
};

export const createCredentialsSlice: StateCreator<CredentialsSlice, [], [], CredentialsSlice> = (set) => ({
  user: getCurrentUser(),
  onLogin: (user: any) => {
    set({
      user,
    });
  },
  onLogout: () => {
    set({
      user: undefined,
    });
  },
});
